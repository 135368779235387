/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Link } from "gatsby";
import { FC } from "react";
import { Hero } from "../components/Hero";
import Layout from "../components/Layout/Layout";
import { CallToAction } from "../components/sections/CallToAction";
import { CallToActionMailchimp } from "../components/sections/CallToActionMailchimp";
import HomeSection from "../components/sections/HomeSection";
import OurProducts from "../components/sections/OurProducts";
import SolutionSection from "../components/sections/SolutionSection";

const Home: FC<any> = () => {
  return (
    <Layout currentPage="/" title="Beartwigs">
      <Hero />
      <HomeSection />
      <SolutionSection />
      <CallToActionMailchimp />
      <OurProducts />
      <section id="portfolio" className="portfolio faq">
        <div className="container">
          <div className="section-title">
            <h2>About us</h2>
            <h3>
              Who are <span>we?</span>
            </h3>
            <p>
              Beartwigs was created after a couple of game jams involving their
              founders at Linköping University. The vision became to create
              amazing innovative games and to have fun while developing.
              Beartwigs has offices in Ebbepark at the business incubator East
              Sweden Game.
            </p>
          </div>
          <div className="row portfolio-container">
            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item filter-app"
              css={css`
                position: relative;
              `}
            >
              <img
                src="Logotyp_esg_webb.png"
                alt="ESG Logo"
                className="img-fluid"
                style={{ padding: "30px" }}
              />
              <div className="portfolio-info">
                <h4>East Sweden Game</h4>
                <p>
                  Beartwigs is part of the East Sweden Game business incubator
                </p>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item filter-web"
              css={css`
                position: relative;
              `}
            >
              <img
                src="ebbepark.jpg"
                alt="Ebbepark logo"
                className="img-fluid"
                style={{
                  height: "100%",
                  maxHeight: "300px",
                  minHeight: "170px",
                }}
              />
              <div className="portfolio-info">
                <h4>Ebbepark, Linköping</h4>
                <p>
                  Our current office space, making neighbours with other amazing
                  developers
                </p>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 col-sm-12 portfolio-item filter-web"
              css={css`
                position: relative;
              `}
            >
              <div
                css={css`
                  display: flex;
                  height: 100%;
                  background-color: #2f307c;
                  align-items: center;
                  max-height: 300px;
                  min-height: 170px;
                  padding: 20px 0;
                `}
              >
                <img
                  className="img-fluid"
                  src="accelerator.png"
                  alt="Accelerator certificate"
                />
              </div>

              <div className="portfolio-info">
                <h4>ESG Accelerator</h4>
                <p>
                  Certificate for participating in the ESG accelerator program
                </p>
              </div>
            </div>
          </div>
          <div className="text-center">
            <Link to="/about" className="bt-btn-link">
              See the team
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Home;
