/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { FC } from "react";
import { FadeInSection } from "../FadeInSection";

const HomeSection: FC = () => {
  return (
    <section className="home">
      <div className="container">
        <div className="section-title">
          <h2>What we are up to</h2>
          <FadeInSection time={1000} threshold={0.8}>
            <h3
              css={css`
                font-size: calc(100% + 1.5vw + 1vh) !important;
              `}
            >
              Reinventing the <span>Retro</span> Style
            </h3>
            <p>
              The pixelart game scene is bigger and more mature than ever. At
              Beartwigs, we are working on how we take it further into the
              future.
            </p>
          </FadeInSection>
        </div>
        <div className="row">
          <div
            className="col-lg-6 col-md-12"
            css={css`
              margin-bottom: 20px;
            `}
          >
            <div
              css={css`
                width: 100%;
                padding-top: 100%; /* 1:1 Aspect Ratio */
                position: relative; /* If you want text inside of it */
                overflow: hidden;
              `}
            >
              <video
                css={css`
                  position: absolute;
                  top: 0;
                  left: 0;
                  bottom: 0;
                  right: 0;
                  height: 100%;
                `}
                autoPlay
                muted
                loop
              >
                <source src="shader_no_low.mp4" type="video/mp4" />
              </video>
              <h1
                css={css`
                  position: absolute;
                  color: white;
                  bottom: 0;
                  left: 60px;
                `}
              >
                Before
              </h1>
            </div>
          </div>
          <div
            className="col-lg-6 col-md-12"
            css={css`
              margin-bottom: 20px;
            `}
          >
            <div
              css={css`
                width: 100%;
                padding-top: 100%; /* 1:1 Aspect Ratio */
                position: relative; /* If you want text inside of it */
                overflow: hidden;
              `}
            >
              <video
                css={css`
                  position: absolute;
                  top: 0;
                  left: 0;
                  bottom: 0;
                  right: 0;
                  height: 100%;
                `}
                autoPlay
                muted
                loop
              >
                <source src="shader_low.mp4" type="video/mp4" />
              </video>
              <h1
                css={css`
                  position: absolute;
                  color: white;
                  bottom: 0;
                  left: 60px;
                `}
              >
                After
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeSection;
