import React, { createRef, FC, ReactNode, useEffect, useState } from "react";

export interface IFadeInSection {
  children: ReactNode;
  threshold?: number;
  time?: number;
  delay?: number;
  noFloat?: boolean;
  className?: string;
}

export const FadeInSection: FC<IFadeInSection> = ({
  children,
  delay = 0,
  time = 300,
  threshold = 0,
  noFloat = false,
  className = "",
}) => {
  const domRef = createRef<HTMLDivElement>();

  const [isVisible, setVisible] = useState(false);
  const [isScreenSmall, setIsScreenSmall] = useState(false);

  useEffect(() => {
    let mobile = false;
    if (window !== undefined) {
      mobile = window.innerHeight < 960;
    }
    setIsScreenSmall(mobile);

    if (domRef.current) {
      const el = domRef.current;
      const observer = new IntersectionObserver(
        entries => {
          if (entries.every(e => e.isIntersecting)) {
            // Not possible to set it back to false like this:
            setVisible(true);

            // No need to keep observing:
            observer.unobserve(el);
          }
        },
        { threshold: mobile ? threshold * 0.5 : threshold }
      );

      observer.observe(el);

      return () => {
        observer.disconnect();
      };
    }
  }, [domRef, threshold]);

  const style: React.CSSProperties = {
    transition: `opacity ${time}ms ease-out, transform ${time}ms ease-out`,
    transitionDelay: `${isScreenSmall ? 0 : delay}ms`,
  };

  const classes =
    (isScreenSmall ? "bt-fade-in-section-mobile" : "bt-fade-in-section") +
    (isVisible ? " bt-is-visible" : "") +
    " " +
    className;

  return (
    <div
      ref={domRef}
      className={classes}
      style={noFloat ? { ...style, transform: "none" } : style}
    >
      {children}
    </div>
  );
};
