/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { StaticImage } from "gatsby-plugin-image";
import { FC } from "react";

const SolutionSection: FC = () => {
  return (
    <section
      id="thesolution"
      css={css`
        margin-bottom: 30px;
      `}
    >
      <div className="container">
        <div className="section-title">
          <h2>How is it done</h2>
          <h3>
            Modern development <span>tools</span> and technology
          </h3>
          <p>
            We work with the latest technology together with the most charming
            art styles. In order to achieve what we want, we are developing a
            new engine and a new set of tools.
          </p>
        </div>
        <div className="row">
          <div className="col-md-12">
            <StaticImage
              src="../../images/engine_screenie_1.png"
              className="img-fluid"
              alt="Engine screenshot 1"
              style={{
                border: "1px solid #f9f9f9",
                boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.4)",
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SolutionSection;
