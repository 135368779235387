/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { FC } from "react";

const OurProducts: FC = () => {
  return (
    <section>
      <div className="container">
        <div className="section-title">
          <h2>What we are working on</h2>
          <h3>
            Our current <span>pipeline</span>
          </h3>
          <p>
            The software being developed side by side is the Candide Engine and
            its first title.
          </p>
        </div>
        <div className="row">
          <div
            className="col-lg-6 col-md-12"
            css={css`
              margin-bottom: 20px;
            `}
          >
            <div
              css={css`
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
              `}
            >
              <a href="https://mailchi.mp/6728137e2718/alpha-signup">
                <div
                  css={css`
                    padding-top: 100%;
                    background: url("screenshots7.jpg") bottom center;
                    background-repeat: false;
                    background-size: cover;
                    position: relative;
                  `}
                >
                  <h1
                    css={css`
                      position: absolute;
                      color: white;
                      text-align: center;
                      bottom: 0;
                      right: 0;
                      left: 0;
                      text-shadow: 0px 0px 18px #000000;
                    `}
                  >
                    Unannounced
                  </h1>
                </div>
              </a>
              <div
                css={css`
                  padding: 20px;
                `}
              >
                Head out on an adventure with your friends in this unannounced
                multiplayer survival game. Build an ancient Roman town, create
                the infrastructure, attract proficient civilians, defend against
                barbarians and challenge the gods.
              </div>
            </div>
          </div>
          <div
            className="col-lg-6 col-md-12"
            css={css`
              margin-bottom: 20px;
            `}
          >
            <div
              css={css`
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
              `}
            >
              <div
                css={css`
                  padding-top: 100%;
                  background: url("engine_screenie_3.png") center center;
                  background-repeat: false;
                  background-size: cover;
                  position: relative;
                `}
              >
                <h1
                  css={css`
                    position: absolute;
                    color: white;
                    text-align: center;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    text-shadow: 0px 0px 18px #000000;
                  `}
                >
                  Candide Engine
                </h1>
              </div>
              <div
                css={css`
                  padding: 20px;
                `}
              >
                A modern 3D graphics engine for 2D retro style games. Includes
                tools for quickly creating 3D models from pixel art. Comes with
                an integrated level editor with real time testing for rapid game
                development.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurProducts;
