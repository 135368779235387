/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { FC } from "react";

export interface IHero {}

export const Hero: FC<IHero> = () => {
  return (
    <section id="hero">
      <div
        css={css`
          position: absolute;
          bottom: 0;
          top: 0;
          left: 0;
          right: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          text-align: center;
          height: auto;
          transform: translateX(max(0px, calc(20vh - 20vw)));
        `}
      >
        <video
          css={css`
            height: 100%;
            width: 237.037037037037vh; /* 100 * 64 / 27 */
            min-width: 100%;
            min-height: 42.1875vw; /* 100 * 27 / 64 */
          `}
          height="auto"
          autoPlay
          muted
          loop
        >
          <source src="video_header.webm" type="video/webm" />
        </video>
      </div>
      <div
        className="d-none d-lg-block"
        css={css`
          position: absolute;
          bottom: -1.65vw;
          left: 0;
          width: 100%;
        `}
      >
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-lg-6"
              css={css`
                padding: 0;
              `}
            >
              <img
                className="img-fluid"
                css={css`
                  width: 100%;
                `}
                src="beartwigs_name_long_white_pathed.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="d-sm-block d-lg-none "
        css={css`
          position: absolute;
          bottom: -3.3vw;
          left: 0;
          width: 100%;
        `}
      >
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-sm-12"
              css={css`
                padding: 0;
              `}
            >
              <img
                className="img-fluid"
                src="beartwigs_name_long_white_pathed.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
